import React, { forwardRef } from 'react';

export default forwardRef((props, ref) => (
    <div className='faq' ref={ref}>
        <div className='faq__content'>
            <h2>Frequently asked questions</h2>
            <div className='divider'></div>
            <div className='faq__questions'>
            <div className='faq__question'>
                    <h4>What is a Daily Streak?</h4>
                    <p className='faq-body'>Your Daily Streak shows how many consecutive days you have reached your Daily Goal. Be careful, missing a day will reset your Daily Streak!</p>
                </div>
                <div className='faq__question'>
                    <h4>What are Bonus Courses?</h4>
                    <p className='faq-body'>Bonus Courses are optional courses that can be purchased from the Store. Bonus Courses allow you to learn about different topics that interest you.<br/><br/>Our first Bonus Courses are on Music and Artists and Famous Places.</p>
                </div>
                <div className='faq__question'>
                    <h4>When will new courses be added?</h4>
                    <p className='faq-body'>We are always working on updating our current courses and adding new ones. We will be sure to let you know when new courses are added!</p>
                </div>
                <div className='faq__question'>
                    <h4>What is a spaced-repition algorithm (SRS)?</h4>
                    <p className='faq-body'>Linggo uses a spaced-repetition algorithm (SRS) to aid you in learning and retaining information. When completing daily reviews, Linggo will intelligently ask you questions on what you actually need to practice to help you retain what you have learned for longer.</p>
                </div>
                <div className='faq__question'>
                    <h4>Can I really use the app completely offline?</h4>
                    <p className='faq-body'>Yes! Linggo was built from the ground up to be used online or offline. This means that whether you are commuting, travelling, or just without WiFi, you can still learn Chinese wherever you are!<br/><br/>*Speaking questions are not available offline.</p>
                </div>
                <div className='faq__question'>
                    <h4>Can I change the text-to-speech voice?</h4>
                    <p className='faq-body'>We currently do not support changing the text-to-speech voice.<br/><br/>For Android users, when offline Linggo will use the default text-to-speech (TTS) engine installed on your device. This means that depending on your device model, the text-to-speech quality may differ. If you are not happy with your offline text-to-speech quality, we recommend downloading another TTS engine from the Play Store.</p>
                </div>
            </div>
        </div>
    </div>
));
