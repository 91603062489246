import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useAuth } from './contexts/AuthContext'

export default function PrivateRoute({ component: Component, ...rest }) {
    const { currentUser } = useAuth()
    return (
        <Route
            {...rest}
            // if someone tries to go to a private rroute without being logged in they will be send back to the home page
            render={props => currentUser ? <Component{...props} /> : <Redirect to="/" />}
        ></Route>
    )
}
