import React from 'react';

import logo from '../../images/feature-wifi.png';

export default function () {
    return <div className='feature feature__feature-3'>
        <div className='feature__content'>
            <div className='feature__text'>
                <h2>No wifi? No problem!</h2>
                <p>Connection issues are a thing of the past! Linggo is built to be used online or offline, no matter where you are!</p>
            </div>
            <img className='feature__img' src={logo} alt='' />
        </div>
    </div>
}
