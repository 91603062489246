import React, { useRef, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { useAuth } from '../../contexts/AuthContext';
import './sign-in.scss';

export default function Login() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const { login } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const [mounted, setMounted] = useState(true);

    useEffect(() => {
        setMounted(true)
        return () => { setMounted(false) }; // use effect cleanup to set flag false, if unmounted
    }, [])

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError('')
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value)
            history.push("/profile-settings")
        } catch {
            setError('Incorrect email or password')
        }
        if (mounted) {
            setLoading(false)
        }
    }

    return (
        <div className="sign-in__wrapper">
            <div className="card card--sign-in">
                <div className="card__content">
                    <div className="heading">Sign In</div>
                    <form onSubmit={handleSubmit}>
                        <div id="email">
                            <label>Email</label>
                            <input type="email" ref={emailRef} required />
                        </div>
                        <div id="password">
                            <label>Password</label>
                            <input type="password" ref={passwordRef} required />
                        </div>
                        <button disabled={loading} className='button button__sign-in' type='submit'>
                            Sign In
                        </button>
                    </form>
                    <Link className="forgot-password" to="/forgot-password">I've forgotten my password</Link>
                </div>
            </div>
            {error &&
                <div className="card card--sign-in-error">{error}</div>
            }
        </div>
    )
}