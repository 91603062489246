import React from 'react';

import logo from '../../images/feature-bonusCourses.png';

export default function () {
    return <div className='feature feature__feature-4'>
        <div className='feature__content'>
            <img className='feature__img' src={logo} alt='' />
            <div className='feature__text'>
                <h2>Learn what matters to you</h2>
                <p>Everyone is different, that's why we create courses on a wide range of topics.</p>
                <p>New Bonus Courses are being added all the time. See which ones interest you!</p>
            </div>
        </div>
    </div>
}
