import React from 'react';
import { HashScroll } from "react-hash-scroll";

import Intro from './intro';
import Feature1 from './feature-1';
import Feature2 from './feature-2';
import Feature3 from './feature-3';
import Feature4 from './feature-4';
import Feature5 from './feature-5';
import FAQ from './faq';
import AppInfo from './app-info';
import Footer from '../../components/footer/footer';

import './home.scss';

export default function Home() {
    return (
        <div>
            <Intro></Intro>
            <HashScroll hash='features' behavior="smooth" position="end">
                <Feature1></Feature1>
            </HashScroll>
            <Feature2></Feature2>
            <Feature5></Feature5>
            <Feature4></Feature4>
            <Feature3></Feature3>
            <FAQ></FAQ>
            <AppInfo></AppInfo>
            <Footer></Footer>
        </div>
    )
}
