import React from 'react';
import { Link } from 'react-router-dom';

import appIcon from '../../images/icon_100_rounded.png';
import googlePlay from '../../images/linggo-play-store-badge-blue.png';
import appStore from '../../images/linggo-app-store-badge-blue.png';

export default function () {
    return <div className='app-info'>
        <div className='app-info__content'>
        <img className='shadow' src={appIcon} alt='' />
        <div className='divider'></div>
            <h2>Get Linggo on Android and iOS now!</h2>
        </div>
        <div className='app-info__links'>
            <Link className='button button--image' to={{ pathname: 'https://play.google.com/store/apps/details?id=io.linggo' }} target="_blank">
                <img className='feature__img' src={googlePlay} alt='' />
            </Link>
            <Link className='button button--image' to={{ pathname: 'https://apps.apple.com/app/id1561683911' }} target="_blank">
                <img className='feature__img' src={appStore} alt='' />
            </Link>
        </div>
    </div >
}
