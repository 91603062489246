import React, { forwardRef } from 'react';

import logo from '../../images/feature-review.png';

export default forwardRef((props, ref) => (
    <div className='feature feature__feature-1' ref={ref}>
        <div className='feature__content'>
            <div className='feature__text'>
                <h2>Never forget vocab again</h2>
                <p>Our advanced spaced-repetition (SRS) algorithms make sure you never forget new words by asking you to review them at the most optimal times.</p>
            </div>
            <img className='feature__img' src={logo} alt='' />
        </div>
    </div>
));
