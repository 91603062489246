export default class ProfileService {
    constructor({ emailRef, nameRef, setError, userToken, userUpdated, authContext }) {
        this.emailRef = emailRef;
        this.nameRef = nameRef;
        this.setError = setError;
        this.userToken = userToken;
        this.userUpdated = userUpdated;

        this.updateEmail = authContext.updateEmail;
        this.updateProfile = authContext.updateProfile;
    }

    // Load user data (JWT Token, Personalised Ads, etc.)
    loadData({ uid, onSuccess }) {
        this.getToken({
            uid,
            onSuccess: token => {
                this.getUpdated({
                    token, uid,
                    onSuccess: result => {
                        onSuccess({ token, result });
                    },
                    onError: () => {
                        console.log('something went wrong')
                    }
                })
            },
            onError: () => {
                console.log('something went wrong')
            }
        })
    }

    getToken({ uid, onSuccess }) {
        let url = 'https://www.api.linggo.io/api/auth/token'
        fetch(url, {
            method: 'GET',
            headers: {
                'uid': uid,
                'deviceId': '123',
                'exp': '1h'
            }
        })
            .then(res => res.json())
            .then(
                result => {
                    onSuccess(result.token);
                },
                error => {
                    console.log('something went wrong', error);
                }
            );
    }

    getUpdated({ uid, token, onSuccess, onError }) {
        let url = `https://www.api.linggo.io/api/db/user/global/${uid}/updated`;
        return fetch(url, {
            method: 'GET',
            headers: { 'x-auth-token': token }
        })
            .then(res => res.json())
            .then(onSuccess, onError);
    }


    // Update username on MongoDB. Have to do this seperately from Firebase
    updateUsername({ uid }) {
        let url = `https://www.api.linggo.io/api/db/user/${uid}/username`
        const data = {
            "username": this.nameRef.current.value,
            "timeStamp": Date.now(),
            "updated": this.userUpdated + 1
        }
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'uid': uid,
                'x-auth-token': this.userToken
            },
            body: JSON.stringify(data),
        });
    }

    updateProfileInfo({ currentUser, onSuccess, onError }) {
        // Check username to ensure it is between 1-16 characters
        if (this.nameRef.current.value.length < 1 || this.nameRef.current.value.length > 16)
            return this.setError('Username must be 1 to 16 characters long')
        else if (!this.emailRef.current.value)
            return this.setError('Invalid email')

        const promises = []

        if (this.emailRef.current.value !== currentUser.email)
            promises.push(this.updateEmail(this.emailRef.current.value))

        if (this.nameRef.current.value !== currentUser.displayName) {
            promises.push(this.updateProfile({ displayName: this.nameRef.current.value, photoURL: '' }))
            promises.push(this.updateUsername({ uid: currentUser.uid }))
        }

        return Promise.all(promises).then(() => onSuccess()).catch(onError);
    }
}
