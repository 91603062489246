import React from 'react';
import { useHistory } from 'react-router-dom';

// Checks the device used to access this page
function androidOrIOS() {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) { return 'android'; }
    if (/iPad|iPhone|iPod/i.test(userAgent)) { return 'ios'; }
    return 'other'
}

// Used to redirect user to appropriate app store, or the home page if not on mobile
function RedirectToStore() {
    const history = useHistory();
    var result = androidOrIOS();

    if (result === 'android') {
        window.location.href = 'https://play.google.com/store/apps/details?id=io.linggo'
    } else if (result === 'ios') {
        window.location.href = 'https://apps.apple.com/app/id1561683911'
    } else {
        history.push('/'); // go to home page
    }
    var store = result === 'android' ? 'to the Play Store...' : 'to the App Store...';
    if (result === 'other') { store = '...'; }

    return <h1 className="redirect-text">Redirecting {store}</h1>
}

export default RedirectToStore;
