import React from 'react'
import { Link } from 'react-router-dom'

import { useAuth } from '../../contexts/AuthContext';
import logo from '../../images/logo-blue.png';
import logoIcon from '../../images/logo-icon.png';

import './footer.scss'

function Footer() {
    const { currentUser } = useAuth();
    return (
        <div className='footer'>
            <div className='footer__logo'>
                <img src={logoIcon} alt='Logo' className="logo_icon" to='/'></img>
                <img src={logo} alt='Logo' className='logo_text' to='/'></img>
            </div>
            <div className='footer__links'>
                <div className='links__explore'>
                    <h5>Explore</h5>
                    <Link to='/' className='footer__link'>Home</Link>
                    <Link to={{ hash: 'features' }} className='footer__link'>Features</Link>
                    <Link to={currentUser ? '/profile-settings' : '/sign-in'} className='footer__link'>Account</Link>
                </div>
                <div className='links__legal'>
                    <h5>Legal</h5>
                    <Link to='/terms' className='footer__link'>Terms of Use</Link>
                    <Link to='/privacy' className='footer__link'>Privacy Policy</Link>
                </div>
            </div>
            <div className='social-media__container'>
                <span className="fa-stack fa-2x social-media__icon facebook">
                    <a className='icon-link-a' href="https://www.facebook.com/linggoApp">
                        <i className="fab fa-facebook-f fa-stack-1x icon-foreground"></i>
                    </a>
                </span>
                <span className="fa-stack fa-2x social-media__icon instagram">
                    <a className='icon-link-a' href="https://www.instagram.com/linggo_app/">
                        <i className="fab fa-instagram fa-stack-1x icon-foreground"></i>
                    </a>
                </span>
                <span className="fa-stack fa-2x social-media__icon twitter">
                    <a className='icon-link-a' href="https://twitter.com/linggo_app">
                        <i className="fab fa-twitter fa-stack-1x icon-foreground"></i>
                    </a>
                </span>
            </div>
            <div className='link-container'>
                {/* <Link to='/contact' className='footer-link'>Contact</Link> */}
            </div>
            <div className='footer__copyright'>
                Linggo © 2022
            </div>
            <div>
                <small className='website-rights'></small>
            </div>
        </div>
    )
}

export default Footer
