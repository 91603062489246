import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { useAuth } from '../../contexts/AuthContext';
import './update-password.scss';

/// * This is the UpdatePassword / ChangePassword page. User is logged in and chooses to change their password.
export default function UpdatePassword() {
    const passwordRef = useRef()
    const passwordConfirmRef = useRef()
    const { currentUser, updatePassword } = useAuth()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    // Same logic for password as we use in app
    function passwordIsValid(password) {
        const hasUppercase = new RegExp('[A-Z]').test(password)
        const hasDigits = new RegExp('[0-9]').test(password)
        const hasLowercase = new RegExp('[a-z]').test(password)
        const hasSpecialCharacters = new RegExp('[!@#$%^&*(),.?":{}|<>_]').test(password)
        const hasMinLength = password.length >= 6

        return hasDigits & hasUppercase & hasLowercase & hasSpecialCharacters & hasMinLength
    }

    function handleSubmit(e) {
        e.preventDefault()

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match')
        } else if (!passwordIsValid(passwordRef.current.value)) { // make sure password meets requirements
            return setError('Password must be at least 6 characters long, containing upper and lower case characters and at least one number or symbol')
        }

        const promises = []
        setLoading(true)
        setError('')

        promises.push(updatePassword(passwordRef.current.value))

        Promise.all(promises).then(() => {
            sendChangePasswordSuccessEmail()
            history.push('/profile-settings')
        }).catch(() => {
            setError('Failed to change password')
        }).finally(() => {
            setLoading(false)
        })
    }

    // Send a request to Firebase CloudFunctions telling it to send a changePasswordSuccess email to user
    async function sendChangePasswordSuccessEmail() {
        var url = new URL('https://us-central1-linggo-11ba0.cloudfunctions.net/changePasswordSuccess');
        let params = { 'uid': currentUser.uid }
        url.search = new URLSearchParams(params).toString()
        await fetch(url);
    }

    return (
        <div className="update-password__wrapper">
            <div className="card card--update-password">
                <div className="card__content">
                    <div className="heading">Change Password</div>
                    <form onSubmit={handleSubmit}>
                        <div id="password">
                            <label>New Password</label>
                            <input type="password" ref={passwordRef} />
                        </div>
                        <div id="password-confirm">
                            <label>Confirm Password</label>
                            <input type="password" ref={passwordConfirmRef} />
                        </div>
                        <button disabled={loading} className='button button__update-password' type='submit'>
                            Update
                        </button>
                    </form>
                    <Link className="link-cancel" to="/profile-settings">Cancel</Link>
                </div>
            </div>
            {error &&
                <div className="card card--password-error">{error}</div>
            }
        </div>
    )
}
