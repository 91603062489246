import React, { useState } from 'react';
import { Link as RouterLink } from "react-router-dom";

import { useAuth } from '../../contexts/AuthContext';
import logo from '../../images/logo.png';
import logoIcon from '../../images/logo-icon.png';

import './navbar.scss';

function Navbar() {
    const [sidebar, setSidebar] = useState(false)
    const { currentUser } = useAuth();
    return (
        <nav className="navbar">
            <div className='navbar__content'>
                <button className='button button--clear navbar__sidebar-toggle' onClick={() => { setSidebar(!sidebar) }}>
                    <i className={sidebar ? 'fas fa-times' : 'fas fa-bars'} />
                </button>
                <RouterLink to="/">
                    <div className='navbar__logo'>
                        <img src={logoIcon} alt='Logo' className="logo_icon" to='/'></img>
                        <img src={logo} alt='Logo' className='logo_text' to='/'></img>
                    </div>
                </RouterLink>
                <div className='navbar__buttons'>
                    <RouterLink to='/'>
                        <button className='navbar__button'>Home</button>
                    </RouterLink>
                    {/* Commented this out because tapping it from the NavBar doesn't do anything */}
                    {/* <RouterLink to={{ pathname: '/', hash: `#features` }}>
                        <button className='navbar__button'>Features</button>
                    </RouterLink> */}
                    <RouterLink to='/terms'>
                        <button className='navbar__button'>Terms</button>
                    </RouterLink>
                    <RouterLink to='/privacy'>
                        <button className='navbar__button'>Privacy Policy</button>
                    </RouterLink>
                    {currentUser
                        ? <RouterLink to='/profile-settings'>
                            <button className='navbar__button'>Settings</button>
                        </RouterLink>
                        : <RouterLink to='/sign-in'>
                            <button className='navbar__button'>Sign In</button>
                        </RouterLink>
                    }
                </div>
            </div>
            <div className={`backdrop ${sidebar ? 'visible' : ''}`} onClick={() => { setSidebar(false) }}></div>
            <div className={`navbar__sidebar ${sidebar ? 'navbar__sidebar--active' : ''}`}>
                <div className='navbar__buttons'>
                    <RouterLink to='/'>
                        <button className='navbar__button'>Home</button>
                    </RouterLink>
                    <RouterLink to={{ pathname: '/', hash: `#features` }}>
                        <button className='navbar__button'>Features</button>
                    </RouterLink>
                    <RouterLink to='/terms'>
                        <button className='navbar__button'>Terms</button>
                    </RouterLink>
                    <RouterLink to='/privacy'>
                        <button className='navbar__button'>Privacy Policy</button>
                    </RouterLink>
                    {currentUser
                        ? <RouterLink to='/profile-settings'>
                            <button className='navbar__button'>Settings</button>
                        </RouterLink>
                        : <RouterLink to='/sign-in'>
                            <button className='navbar__button'>Sign In</button>
                        </RouterLink>
                    }
                </div>
            </div>
            {/* <div className="navbar-container">
                <Link to="/" onClick={closeMobileMenu}>
                <img src={logo} alt='' className='navbar-logo'></img>
                </Link>
                <div className="menu-icon" onClick={handleClick}>
                <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                <li className='nav-item'>
                <Link to='/terms' className='nav-links' onClick={closeMobileMenu}>Terms of Use</Link>
                </li>
                <li className='nav-item'>
                <Link to='/privacy' className='nav-links' onClick={closeMobileMenu}>Privacy Policy</Link>
                </li>
                <li className='nav-item'>
                <Link to='/sign-in' className='nav-links-mobile' onClick={closeMobileMenu}>Sign In</Link>
                </li>
                </ul>
                {button && <Button buttonStyle='btn--outline'>Sign In</Button>}
                </div> */}
        </nav>
    )
}

export default Navbar
