import React from "react";
import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Home from './pages/home/home';
import Terms from './pages/terms/terms';
import Privacy from './pages/privacy/privacy';
import Login from './pages/sign-in/sign-in';
import Navbar from './components/navbar/Navbar';

import PrivateRoute from "./PrivateRoute";
import Profile from './pages/profile/profile';
import UpdatePassword from "./pages/update-password/update-password";
import ForgotPassword from "./pages/forgot-password/forgot-password";
import RedirectToStore from './RedirectToStore';

import './App.scss';

function App() {
    return (
        <Router>
            <AuthProvider>
                <Navbar />
                {/* <Switch> */}
                <Route path="/redirect-to-store" exact component={RedirectToStore} />
                <Route path="/" exact component={Home} />
                <Route path="/terms" component={Terms} />
                <Route path="/privacy" component={Privacy} />
                <Route path="/sign-in" component={Login} />
                <Route path="/forgot-password" component={ForgotPassword} />

                <PrivateRoute path="/update-password" component={UpdatePassword} />
                <PrivateRoute path="/profile-settings" component={Profile} />
                {/*
                
                <Route path="/usermgmt" component={UserManagement} />
                <Route path="/support" component={Support} />*/}
                {/* </Switch> */}
            </AuthProvider>
        </Router>
    )
}

export default App;


/*

linggo colours:

// const int kPureWhite = 0xFFFFFFFF;      // #FFFFFF
/// Off white color for page backgrounds. Called PanelWhite in Unity version.
const int kPageWhite = 0xFFF2F2F2;      // #F2F2F2
/// Light grey color used for unselected option slates
const int kLightGrey = 0xFFBDBDBD;      // #BDBDBD
const int kGrey = 0xFFA9A9A9;           // #A9A9A9
const int kBlack = 0xFF212121;          // #212121
const int kLightBlue = 0xFF90CAF9;      // #90CAF9
const int kDarkBlue = 0xFF2D9AF3;       // #2D9AF3
const int kDarkerBlue = 0xFF1C80D1;     // #1C80D1
const int kLightRed = 0xFFF990BF;       // #F990BF
const int kDarkRed = 0xFFF42D2C;        // #F42D2C
const int kLightGold = 0xFFF5C391;      // #F5C391
const int kDarkGold = 0xFFF3972C;       // #F3972C
const int kLightOrange = 0xFFF9B390;    // #F9B390
const int kDarkOrange = 0xFFF36E2C;     // #F36E2C
const int kLightPurple = 0xFFD191F2;    // #D191F2
const int kDarkPurple = 0xFFAF30F3;     // #AF30F3
const int kCoinYellow = 0xFFFFD100;// #FFD100

*/