import React from 'react';

import logo from '../../images/feature-speaking.png';

export default function () {
    return <div className='feature feature__feature-2'>
        <div className='feature__content'>
            <img className='feature__img' src={logo} alt='' />
            <div className='feature__text'>
                <h2>Practice speaking the Linggo</h2>
                <p>Never worry about your pronunciation again with the help of our speech recognition questions. Practice saying words and full sentences so you can speak Chinese with confidence.</p>
            </div>
        </div>
    </div>
}
