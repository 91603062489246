import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import { useAuth } from '../../contexts/AuthContext';
import './forgot-password.scss';

export default function ForgotPassword() {
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [loading, setLoading] = useState(false)
    const emailRef = useRef()
    const { resetPassword } = useAuth()

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setSuccess('')
            setError('')
            setLoading(true)
            await resetPassword(emailRef.current.value)
            setSuccess('Please check your inbox for further instructions.\nIf you have not received an email please check your junk folder or try again.')
        } catch {
            setError('Failed to reset password')
        }
        setLoading(false)
    }

    return (
        <div className="forgot-password__wrapper">
            <div className="card card--forgot-password">
                <div className="card__content">
                    <div className="heading">Reset Password</div>
                    <form onSubmit={handleSubmit}>
                        <div id="email">
                            <label>Email</label>
                            <input type="email" ref={emailRef} required />
                        </div>
                        <button disabled={loading} className='button button__reset' type='submit'>
                            Reset Password
                        </button>
                    </form>
                    <Link className="sign-in" to="/sign-in">Sign In</Link>
                </div>
                {error &&
                    <div className="card card--forgot-password-error">{error}</div>
                }
                {success &&
                    <div className="card card--forgot-password-success">{success}</div>
                }
            </div>
        </div>
    )
}
