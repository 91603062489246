import React from 'react';

import logo from '../../images/feature-sentences.png';

export default function () {
    return <div className='feature feature__feature-5'>
        <div className='feature__content'>
            <div className='feature__text'>
                {/* <h2>Put how you feel into words</h2> */}
                <h2>Express yourself</h2>
                <p>Learn how to construct sentences so you can fully express yourself in your new language.</p>
            </div>
            <img className='feature__img' src={logo} alt='' />
        </div>
    </div>
}
