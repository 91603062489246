import React from 'react';
import { Link } from 'react-router-dom';

import googlePlay from '../../images/linggo-play-store-badge.png';
import appStore from '../../images/linggo-app-store-badge.png';
import image from '../../images/mainFeature.png';

export default function Intro() {
    return <div className='intro'>
        <div className='intro__content'>
            <img className='intro__img' src={image} alt='' />
            <div className='intro__text'>
                <h1>Learn a language with Linggo!</h1>
                <p>Our fun and engaging lessons are the best way to go from beginner to master of Chinese!</p>
                <div className='divider'></div>
                <div className='intro__links'>
                    <Link className='button button--image' to={{ pathname: 'https://play.google.com/store/apps/details?id=io.linggo' }} target="_blank">
                        <img className='feature__img' src={googlePlay} alt='' />
                    </Link>
                    <Link className='button button--image' to={{ pathname: 'https://apps.apple.com/app/id1561683911' }} target="_blank">
                        <img className='feature__img' src={appStore} alt='' />
                    </Link>
                </div>
            </div>
        </div>
    </div>
}
