import React, { useRef, useState, useEffect } from "react";

import { useAuth } from '../../contexts/AuthContext';
import ProfileService from './profile.service';
import './profile.scss';

export default function ProfileSettings() {
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [userToken, setUserToken] = useState(0);
    const [userUpdated, setUserUpdated] = useState(0)
    const emailRef = useRef();
    const nameRef = useRef();

    const { currentUser, deleteUser, signOut } = useAuth();
    const authContext = useAuth();
    const service = new ProfileService({ emailRef, nameRef, setError, userToken, userUpdated, authContext });

    useEffect(() => {
        service.loadData({
            uid: currentUser.uid,
            onSuccess: ({ token, result }) => {
                setUserToken(token)
                setUserUpdated(parseInt(result))
            },
            onError: error => {
                console.log('something went wrong', error)
            }
        })
    }, [currentUser])

    // Displays window confirm alert to ask if user is sure they want to delete account
    function deleteConfirmAlert() {
        var r = window.confirm('Are you sure you want to delete your account?')
        if (r === true) {
            deleteUser()
        }
    }

    const handleSubmit = () => {
        setLoading(true);
        setError('');
        setSuccess('');
        service.updateProfileInfo({
            currentUser,
            onSuccess: () => {
                setSuccess('Profile updated!')
            },
            onError: () => {
                setError('Failed to update account')
            }
        })
    }

    return (
        <div className="profile__wrapper">
            <div className="card card--profile">
                <div className='card__content'>
                    <div className="heading">Profile Settings</div>
                    <div onSubmit={handleSubmit}>
                        <div className="form__row">
                            <label>Username</label>
                            <input type="name" ref={nameRef} defaultValue={currentUser.displayName} />
                        </div>
                        <div className="form__row">
                            <label>Email</label>
                            <input type="email" ref={emailRef} defaultValue={currentUser.email} />
                        </div>
                        <div className="form__row">
                            <label>Password</label>
                            <a className='icon-link-a' href="/update-password">
                                <button className="button button--clear" disabled={loading}>Change Password</button>
                            </a>
                        </div>
                        <div className="form__controls">
                            <button disabled={loading} className="button button--clear" onClick={signOut}>Log Out</button>
                            <button disabled={loading} className="button" onClick={handleSubmit}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
            {error &&
                <div className="card card--profile-error">{error}</div>
            }
            {success &&
                <div className="card card--profile-success">{success}</div>
            }
            <div className="card card--profile">
                <div className='card__content'>
                    <div className="heading">Account Settings</div>
                    <div className="form__row">
                        <label>Delete Account?</label>
                        <button className="button button--red" onClick={deleteConfirmAlert} disabled={loading}>Delete Account</button>
                    </div>
                </div>
            </div>
        </div>
    )
}